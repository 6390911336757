import {defineStore} from "pinia";
import {ref} from "vue";
import backend from "@/util/backend";
import {useFormStore} from "@/stores/FormStore";
import Notifier from "@/util/notifier";
import ViewStep from "@/types/viewStep";

export const useRefuseStore = defineStore('refuse', () => {
  const notifier = Notifier()
  const formStore = useFormStore()
  const reason = ref('')

  const refuseModal = ref('refuseModal')

  const showRefuse = () => {
    reason.value = ''
    refuseModal.value.modal.open()
  }

  const refuse = async () => {
    const id = formStore.form?.id
    if (!id) {
      return notifier.error('toast.refuse_failed')
    }
    try {
      const result = await backend.put(`api/documents/${id}/refuse`, {
        'reason': reason.value,
      })
      if (result.status !== 200) {
        notifier.error('toast.refuse_failed')
      } else {
        notifier.success('toast.transport_refused')
        formStore.setForm(result.data)
        formStore.viewStep = ViewStep.VIEWING
      }
    } catch (err) {
      notifier.error('toast.refuse_failed')
    } finally {
      refuseModal.value.modal.close()
    }
  }

  return {
    refuseModal,
    showRefuse,
    refuse,
    reason,
  }
})
<template>
  <layout :key="user.language">
    <template #header>
      <ui-header :title="loading ? '' : $t('waste_identification.view.title', {id: link.documentId})">
        <template #actions>
          <template v-if="!loading && step === steps.viewing">
            <div class="btn-group pe-3">
              <button id="changeLogBtn" class="btn btn-outline-secondary" @click="changeLogStore.showChangeLog()">
                <i class="bi-pdf"/>&nbsp;{{ $t('waste_identification.view.view_change_log') }}
              </button>
              <button
                v-if="!FormState.isEndState(form.state)"
                type="button" class="btn btn-outline-secondary"
                @click="editWeightsStore.showEditWeights()"
              >
                <i class="bi-pencil"/>&nbsp;{{ $t('share_link.processor.edit_weights') }}
              </button>
            </div>
          </template>
        </template>
      </ui-header>
    </template>
    <ui-content class="pb-1">
      <spinner v-if="loading"/>
      <template v-else-if="step === steps.userinfo">
        <div class="container-fluid">
          <form id="user-form" class="row g-2 justify-content-center">
            <ui-view-card data-cy="information" :title="$t('shared.processor_information')" :key="user.language">
              <template #actions>
                <button id="saveUserBtn" v-if="user.language" type="button" class="btn" :class="'btn-outline-primary'"
                        @click="saveUser()" form="user-form" :disabled="userSaving"><i
                  class="bi-save"/>&nbsp;{{ $t('general.confirm') }}
                </button>
              </template>
              <ui-view-card-language v-model="user.language" :required="true" :own-language="true"/>
              <template v-if="user.language">
                <ui-view-card-field data-cy="processor-email" :label="$t('user.labels.email')" v-model="user.email"
                                    :required="true" :readonly="true"/>
                <ui-view-card-field data-cy="processor-first-name" :label="$t('user.labels.first_name')"
                                    v-model="user.firstName" :required="true"/>
                <ui-view-card-field data-cy="processor-last-name" :label="$t('user.labels.last_name')"
                                    v-model="user.lastName" :required="true"/>
                <ui-view-card-field data-cy="processor-organisation" :label="$t('user.labels.organisation')"
                                    v-model="user.organisation" :required="true"/>
              </template>
            </ui-view-card>
          </form>
        </div>
      </template>
      <template v-else-if="step === steps.viewing">
        <waste-identification-form-view :form="form" :key="form.id+'-'+form.lastUpdate"/>
      </template>
    </ui-content>
  </layout>
</template>

<script>

import UiContent from '@/components/ui-content'
import {inject, ref, watch} from 'vue'
import UiViewCard from '@/components/ui-view-card'
import UiViewCardField from '@/components/ui-view-card-field'
import Notifier from '@/util/notifier'
import backend from '@/util/backend'
import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import WasteIdentificationFormView from '@/partials/waste-identification/FormView'
import Spinner from '@/components/spinner'
import UiViewCardLanguage from '@/components/ui-view-card-language'
import FormState from '@/types/formState'
import {storeToRefs} from 'pinia'
import {useSharedLinkStore} from '@/stores/SharedLinkStore'
import {useEditWeightsStore} from '@/stores/modals/EditWeightsStore'
import {useChangeLogStore} from '@/stores/modals/ChangeLogStore'

export default {
  name:       'SharedLinkProcessor',
  computed:   {
    FormState() {
      return FormState
    },
  },
  components: {
    UiViewCardLanguage,
    Spinner,
    WasteIdentificationFormView,
    UiHeader,
    Layout,
    UiViewCardField,
    UiViewCard,
    UiContent,
  },
  props:      {
    link: {
      type:     Object,
      required: true,
    },
  },
  setup:      (props) => {
    const $tset    = inject('$tset')
    const notifier = Notifier()

    const sharedLinkStore  = useSharedLinkStore()
    const editWeightsStore = useEditWeightsStore()
    const changeLogStore   = useChangeLogStore()

    const steps = {
      userinfo: 'userinfo',
      viewing:  'viewing',
    }
    const step  = ref(steps.userinfo)

    const user = ref({
      email:        props.link.email,
      language:     props.link.language,
      firstName:    props.link.firstName,
      lastName:     props.link.lastName,
      organisation: props.link.organisation,
    })
    if (user.value.language) {
      $tset(user.value.language)
    }
    watch(() => user.value.language, (l) => {
      $tset(l)
    })

    const userSaving = ref(false)
    const saveUser   = () => {
      userSaving.value = true
      const userForm   = $('#user-form')[0]
      if (!userForm.checkValidity()) {
        // something in the form is not valid so don't allow signing
        userForm.reportValidity()
        userSaving.value = false
        return
      }
      backend.put(`/api/sharedlinks/${props.link.id}/userData`, {
        firstName:    user.value.firstName,
        lastName:     user.value.lastName,
        organisation: user.value.organisation,
        language:     user.value.language,
      }).then(() => {
        notifier.success('toast.user_confirmed')
        step.value = steps.viewing
      }).finally(() => {
        userSaving.value = false
      })
    }

    return {
      ...storeToRefs(sharedLinkStore),
      sharedLinkStore,
      editWeightsStore,
      changeLogStore,

      step,
      steps,
      user,
      saveUser,
      userSaving,
    }
  },
}
</script>

const imoNumberValidator = {
  validate: (value) => {
    const regex = new RegExp(/^(IMO[0-9]{7})$/)
    if (!regex.test(value)) {
      return false
    }

    const split = value.replace('IMO', '').split('')
    const sum = split[0]*7+split[1]*6+split[2]*5+split[3]*4+split[4]*3+split[5]*2
    const remainder = sum % 10
    return remainder == split[6]
  },
}

export default imoNumberValidator
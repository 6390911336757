<template>
  <ui-modal ref="modal" :callback="cb" size="xl" :can-confirm="filledIn">
    <template #title>
      {{ $t('support.translation_modal.title') }}
    </template>
    <template #default>
      <ui-view-card-field v-model="translationRef.translationKey"
                          :label="$t('support.translation_modal.translation_key')" :readonly="!translationRef.new"/>
      <ui-view-card-field v-model="translationRef.en" :maxlength="maxLength"
                          :label="$t('support.translation_modal.en')"/>
      <ui-view-card-field v-model="translationRef.nl" :maxlength="maxLength"
                          :label="$t('support.translation_modal.nl')"/>
      <ui-view-card-field v-model="translationRef.fr" :maxlength="maxLength"
                          :label="$t('support.translation_modal.fr')"/>
      <ui-view-card-field v-model="translationRef.de" :maxlength="maxLength"
                          :label="$t('support.translation_modal.de')"/>
    </template>
  </ui-modal>
</template>

<script>

import UiModal from '@/components/ui-modal.vue'
import {ref} from 'vue'
import UiViewCardField from '@/components/ui-view-card-field.vue'

export default {
  name:       'TranslationModal',
  components: {
    UiViewCardField,
    UiModal,
  },
  props:      {
    callback: {
      type:     Function,
      required: true,
    },
  },
  setup:      (props) => {
    const modal = ref('modal')

    const translationRef = ref({})

    const cb = async () => {
      await props.callback(translationRef.value)
      modal.value.close()
    }

    const filledIn = () => {
      return translationRef.value.translationKey
        && translationRef.value.en
        && translationRef.value.nl
        && translationRef.value.fr
        && translationRef.value.de
    }

    const maxLength = 1000

    return {
      modal,
      translationRef,
      cb,
      filledIn,
      maxLength
    }
  },
}
</script>
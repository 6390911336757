<template>
  <template v-for="(identification, index) in model" :key="identification.id">
    <div class="pb-1" data-cy="wi-id-number">
      <label class="pe-2">
        {{ $t('form.labels.identification_number') }}<span v-if="required" :title="$t('general.required')"
                                                           class="text-danger">*</span>
      </label>
      <div class="d-flex">
        <div class="container flex-column">
          <div class="row">
            <v-select
              :data-cy="cy+'-id-type'"
              class="col form-control"
              :class="{'col': !$isMobile}"
              :options="identificationTypes"
              v-model="identification.identificationType"
              :reduce="idType => idType.value"
              @close="validateIdentification(identificationRefs[index])"
            >
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="required && !identification.identificationType"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
              <template #no-options>
                {{ $t('general.no_options') }}
              </template>
              <template #option="option">
                {{ option.label }}
                <span v-if="requiredTypes.indexOf(option.value) > -1" :title="$t('general.required')" class="text-danger">*</span>
              </template>
            </v-select>
            <input
              :data-cy="cy+'-id-value'"
              class="form-control"
              :class="[identification.identificationType ? identification.identificationType+'-identification' : '',
                  {
                    'identification-to-validate': typesToValidate.includes(identification.identificationType),
                    'col': !$isMobile,
                    'invalid-field': identification.invalid
                  }]"
              v-model="identification.value"
              :required="required" maxlength="30"
              :style="identification.identificationType === IdentificationType.VAT ? 'text-transform:uppercase' : ''"
              @blur="validateIdentification(identificationRefs[index])"
              @input="validateIdentification(identificationRefs[index])"
              :disabled="identification.identificationType === IdentificationType.INDIVIDUAL"
              :ref="(el) => {identificationRefs[index] = {element: el, identification: identification}}"
            >
            <i
              v-if="identification.identificationType === IdentificationType.VAT"
              style="font-size: 0.75rem; position: absolute"
              class="bi-question-circle-fill"
              :title="$t('form.vat_title')"
            />
            <i
              v-if="identification.identificationType === IdentificationType.BRANCH_NUMBER"
              style="font-size: 0.75rem; position: absolute"
              class="bi-question-circle-fill"
              :title="$t('form.branch_number_title')"
            />
            <i
              v-if="identification.identificationType === IdentificationType.COMPANY"
              style="font-size: 0.75rem; position: absolute"
              class="bi-question-circle-fill"
              :title="$t('form.company_title')"
            />
            <i
              v-if="identification.identificationType === IdentificationType.MMSI"
              style="font-size: 0.75rem; position: absolute"
              class="bi-question-circle-fill"
              :title="$t('form.mmsi_title')"
            />
            <i
              v-if="identification.identificationType === IdentificationType.IMO"
              style="font-size: 0.75rem; position: absolute"
              class="bi-question-circle-fill"
              :title="$t('form.imo_title')"
            />
          </div>
        </div>
        <div v-if="index === 0" class="btn btn-primary d-inline-flex align-items-center" @click="addId()">
          <i class="bi-plus"/>
        </div>
        <div v-if="index > 0" class="btn btn-danger d-inline-flex align-items-center" @click="deleteId(index)">
          <i class="bi-trash"/>
        </div>
      </div>
    </div>
  </template>
</template>

<script>

import {useModelWrapper} from '@/util/modelWrapper'
import {inject, ref, watch} from 'vue'
import IdentificationType from '@/types/identificationType'
import companyNumberValidator from '@/services/companyNumberValidator'
import branchNumberValidator from '@/services/branchNumberValidator'
import identificationService from '@/services/identificationService'
import vatNumberValidator from '@/services/vatNumberValidator'
import imoNumberValidator from '@/services/imoNumberValidator'
import mmsiNumberValidator from '@/services/mmsiNumberValidator'

export default {
  name:       'ui-view-card-ids',
  components: {},
  props:      {
    modelValue:      {
      default: [],
    },
    required:        {
      type:    Boolean,
      default: false,
    },
    type:            {
      type:    String,
      default: 'text',
    },
    cy:              {
      type:    String,
      default: '',
    },
    allowIndividual: {
      type:    Boolean,
      default: false,
    },
    countryCode:     {
      type:    String,
      default: '',
    },
    requiredTypes:   {
      type:    Array,
      default: () => [],
    }
  },
  setup:      (props, {emit}) => {
    const $t                 = inject('$t')
    const model              = useModelWrapper(props, emit)
    const identificationRefs = ref([])

    const typesToValidate = identificationService.typesToValidate

    const addId = () => {
      model.value.push({})
    }

    if (model.value.length < 1) {
      console.error('Should have at least one ID')
      addId()
    }

    const deleteId = (index) => {
      model.value.splice(index, 1)
    }

    const identificationTypes = ref(IdentificationType.getAll(props.allowIndividual))

    const validateIdentification = (reference) => {
      if (!reference?.identification?.value) {
        return
      }

      resetInvalid(reference)

      switch (reference.identification.identificationType) {
        case IdentificationType.VAT: {
          reference.identification.value = reference.identification.value.toLocaleUpperCase()
          reference.identification.value = reference.identification.value.replaceAll(/[^a-zA-Z0-9]/g, '')
          if (!vatNumberValidator.validate(props.countryCode, reference.identification.value)) {
            reference.element?.setCustomValidity($t('error.invalid_vat'))
            reference.identification.invalid = true
          }
          break
        }
        case IdentificationType.BRANCH_NUMBER: {
          reference.identification.value = reference.identification.value.replaceAll(/\D/g, '')
          if (!branchNumberValidator.validate(props.countryCode, reference.identification.value)) {
            reference.element?.setCustomValidity($t('error.invalid_branch_number'))
            reference.identification.invalid = true
          }
          break
        }
        case IdentificationType.COMPANY: {
          reference.identification.value = reference.identification.value.replaceAll(/\D/g, '')
          if (!companyNumberValidator.validate(props.countryCode, reference.identification.value)) {
            reference.element?.setCustomValidity($t('error.invalid_company_number'))
            reference.identification.invalid = true
          }
          break
        }
        case IdentificationType.IMO: {
          reference.identification.value = reference.identification.value.toLocaleUpperCase()
          reference.identification.value = reference.identification.value.replaceAll(/[^a-zA-Z0-9]/g, '')
          if (!imoNumberValidator.validate(reference.identification.value)) {
            reference.element?.setCustomValidity($t('error.invalid_imo_number'))
            reference.identification.invalid = true
          }
          break
        }
        case IdentificationType.MMSI: {
          reference.identification.value = reference.identification.value.replaceAll(/\D/g, '')
          if (!mmsiNumberValidator.validate(reference.identification.value)) {
            reference.element?.setCustomValidity($t('error.invalid_mmsi_number'))
            reference.identification.invalid = true
          }
          break
        }
      }
    }

    const resetInvalid = (reference) => {
      reference.identification.invalid = false
      reference?.element && reference.element.setCustomValidity('')
    }

    watch(() => model, (val) => {
      val.value.forEach((v) => {
        if (v.identificationType === IdentificationType.INDIVIDUAL) {
          v.value = $t('identification_types.INDIVIDUAL').toUpperCase()
        } else {
          if (v.value === $t('identification_types.INDIVIDUAL').toUpperCase()) {
            v.value = ''
          }
        }
      })
    }, {deep: true})

    watch(() => props.countryCode, () => {
      validateAll()
    })

    const validateAll = () => {
      identificationRefs.value.forEach((reference) => {
        validateIdentification(reference)
      })
    }

    // wait until page is rendered, then get elements and validate
    setTimeout(() => {
      validateAll()
    }, 1)

    const triggerValidateAll = () => {
      setTimeout(() => {
        validateAll()
      }, 1)
    }

    return {
      identificationRefs,
      addId,
      deleteId,
      identificationTypes,
      model,
      IdentificationType,
      resetInvalid,
      validateIdentification,
      typesToValidate,
      validateAll,
      triggerValidateAll,
    }
  },
}
</script>

<template>
  <template v-if="addressRef">
    <template v-if="edit">
      <div class="row">
        <div class="col-lg-6">
          <ui-view-card-field autocomplete="address-line1" :data-cy="cy+'-street'" :label="$t('address.street')"
                              v-model="addressRef.street" :required="required"/>
        </div>
        <div class="col-lg-6">
          <ui-view-card-field autocomplete="address-line2" :data-cy="cy+'-number'" :label="$t('address.number')"
                              v-model="addressRef.number" :required="required" :maxlength="20"/>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <Popper arrow class="light" @close="closeZipPopper" :show="zipSuggestions.length > 0"
                  v-click-outside="closeZipPopper">
            <ui-view-card-field autocomplete="postal-code" :data-cy="cy+'-zip'" :label="$t('address.zip')"
                                v-model="addressRef.zip" :required="required" :type="zipNumber ? 'number' : 'text'"
                                :maxlength="maxZip ? maxZip.toString().length : 20" :min="minZip" :max="maxZip"/>

            <template #content>
              <div v-for="suggestion in zipSuggestions" :key="suggestion" class="popper-suggestion">
                <div @click="useZipSuggestion(suggestion)">
                  <span>{{ suggestion }}</span>
                </div>
              </div>
            </template>
          </Popper>
        </div>
        <div class="col-lg-6">
          <Popper arrow class="light" @close="closeCityPopper" :show="citySuggestions.length > 0"
                  v-click-outside="closeCityPopper">
            <ui-view-card-field autocomplete="address-level2" :data-cy="cy+'-city'" :label="$t('address.city')"
                                v-model="addressRef.city" :required="required"/>
            <template #content>
              <div v-for="suggestion in citySuggestions" :key="suggestion" class="popper-suggestion">
                <div @click="useCitySuggestion(suggestion)">
                  <span>{{ suggestion }}</span>
                </div>
              </div>
            </template>
          </Popper>
        </div>
      </div>
      <div>
        <ui-view-card-field autocomplete="country-name" :data-cy="cy+'-country'" :label="$t('address.country')"
                            v-model="addressRef.country" :required="required">
          <v-select :options="countries" v-model="addressRef.country" :reduce="country => country.code">
            <template #search="{attributes, events}">
              <input
                class="vs__search"
                :required="required && !addressRef.country"
                v-bind="attributes"
                v-on="events"
              />
            </template>
            <template #no-options>
              {{ $t('general.no_options') }}
            </template>
          </v-select>
        </ui-view-card-field>
      </div>
    </template>
    <template v-else-if="compactAddress">
      {{ firstLine }}
      <br/>
      {{ secondLine }}
    </template>
    <template v-else>
      <div class="d-flex">
        <div>
          {{ $t('address.address') }}:&nbsp;
        </div>
        <div>
          {{ firstLine }}
          <br/>
          {{ secondLine }}
        </div>
      </div>
    </template>
  </template>
</template>

<script>

import UiViewCardField from '@/components/ui-view-card-field'
import {useModelWrapper} from '@/util/modelWrapper'
import {computed, ref, watch} from 'vue'
import CountryCodes from '@/models/CountryCodes'
import session from '@/util/session'
import {AddressFormatter} from '@/services/addressFormatter'
import backend from '@/util/backend'
import {debounce} from '@/util/debounce'

export default {
  name:       'ui-view-card-address',
  components: {UiViewCardField},
  props:      {
    modelValue:     {
      type:    Object,
      default: () => ({}),
    },
    edit:           {
      type:    Boolean,
      default: false,
    },
    required:       {
      type:    Boolean,
      default: false,
    },
    zipNumber:      {
      type:    Boolean,
      default: false,
    },
    compactAddress: {
      type:    Boolean,
      default: false,
    },
    cy:             {
      type:    String,
      default: '',
    },
    maxZip:         {
      type:    String,
      default: '',
    },
    minZip:         {
      type:    String,
      default: '',
    },
  },
  setup:      (props, {emit}) => {
    const addressRef = useModelWrapper(props, emit)
    if (!props.modelValue) {
      addressRef.value = {}
    }

    const firstLine = computed(() => {
      return AddressFormatter.formatAddress(addressRef.value).line1
    })

    const secondLine = computed(() => {
      return AddressFormatter.formatAddress(addressRef.value).line2
    })

    const language = session.getLanguage()

    const countries = []
    countries.push(...CountryCodes.top)
    countries.push(...CountryCodes.rest) // TODO sort rest alphabetically

    const citySuggestions = ref([])
    const zipSuggestions  = ref([])
    watch(() => addressRef.value.zip, debounce(async (zip) => {
      if ((!addressRef.value.country || addressRef.value.country === CountryCodes.BE) && zip && zip.length === 4 && !addressRef.value.city) {
        const result = await backend.get('api/geo?postalCode=' + zip)
        if (result.data) {
          if (result.data.length === 1) {
          addressRef.value.city = result.data[0]
          } else {
            zipSuggestions.value = result.data.sort()
          }
        }
      }
    }, 400))

    watch(() => addressRef.value.city, debounce(async (city) => {
      if ((!addressRef.value.country || addressRef.value.country === CountryCodes.BE) && city && city.length >= 2 && !addressRef.value.zip) {
        const result = await backend.get('api/geo?municipality=' + city)
        if (result.data) {
          if (result.data.length === 1) {
            addressRef.value.zip = result.data[0]
          } else {
            zipSuggestions.value = result.data.sort()
          }
        }
      }
    }, 400))

    const useZipSuggestion = (suggestion) => {
      addressRef.value.zip = suggestion
      closeZipPopper()
    }

    const closeZipPopper = () => {
      zipSuggestions.value = []
    }

    const useCitySuggestion = (suggestion) => {
      addressRef.value.city = suggestion
      closeCityPopper()
    }

    const closeCityPopper = () => {
      citySuggestions.value = []
    }

    return {
      addressRef,
      firstLine,
      secondLine,
      language,
      countries,
      citySuggestions,
      zipSuggestions,
      useZipSuggestion,
      useCitySuggestion,
      closeZipPopper,
      closeCityPopper,
    }
  },
}
</script>

import IdentificationType from "@/types/identificationType";
import branchNumberValidator from "@/services/branchNumberValidator";
import companyNumberValidator from "@/services/companyNumberValidator";
import vatNumberValidator from "@/services/vatNumberValidator";
import imoNumberValidator from '@/services/imoNumberValidator'
import mmsiNumberValidator from '@/services/mmsiNumberValidator'

const identificationService = {
  typesToValidate: [IdentificationType.VAT, IdentificationType.BRANCH_NUMBER, IdentificationType.COMPANY, IdentificationType.IMO],

  validateIdentifications: (model) => {
    for (let i = 0; i < model.identifications.length; i++) {
      const identification = model.identifications[i]

      switch (identification.identificationType) {
        case IdentificationType.VAT: {
          identification.value = identification.value.toLocaleUpperCase()
          identification.value = identification.value.replaceAll(/[^a-zA-Z0-9]/g, '')
          if (!vatNumberValidator.validate(model?.address?.country, identification.value)) {
            return false
          }
          break
        }
        case IdentificationType.BRANCH_NUMBER: {
          identification.value = identification.value.replaceAll(/\D/g, '')
          if (!branchNumberValidator.validate(model?.address?.country, identification.value)) {
            return false
          }
          break
        }
        case IdentificationType.COMPANY: {
          identification.value = identification.value.replaceAll(/\D/g, '')
          if (!companyNumberValidator.validate(model?.address?.country, identification.value)) {
            return false
          }
          break
        }
        case IdentificationType.IMO: {
          identification.value = identification.value.toLocaleUpperCase()
          identification.value = identification.value.replaceAll(/[^a-zA-Z0-9]/g, '')
          if (!imoNumberValidator.validate(identification.value)) {
            return false
          }
          break
        }
        case IdentificationType.MMSI: {
          identification.value = identification.value.replaceAll(/\D/g, '')
          if (!mmsiNumberValidator.validate(identification.value)) {
            return false
          }
          break
        }
      }
    }

    return true
  }
}

export default identificationService
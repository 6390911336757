<template>
  <layout>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
        <ui-list-tabs v-model="currentTab" :tabs="[TABS.ORGANISER, TABS.PRODUCER, TABS.PROCESSOR, TABS.TRANSPORTER]"/>
      </div>
      <div>
        <button class="btn btn-sm btn-outline-secondary"
                @click="formListStore.resetGrid()">{{
            $t('general.reset_filters')
          }}
        </button>
      </div>
    </div>
    <ui-content>
      <div class="tab-content h-100">
        <div
          :class="{
            'show': true,
            'active': true
          }"
          class="tab-pane fade h-100"
          role="tabpanel"
        >
          <div class="d-flex flex-column h-100">
            <ag-grid-vue
              :serverSideDatasource="dataSource"
              :grid-options="grid.defaultOptions"
              :columnDefs="columnDefs"
              class="ag-theme-quartz"
              style="min-height:100%"
              @row-clicked="externalStore.formRowClick"
              @selection-changed="formListStore.refreshSelection"
              @grid-ready="formListStore.onGridReady"
              @grid-pre-destroyed="formListStore.onGridPreDestroyed"
              @filter-changed="formListStore.onFilterChanged"
              @first-data-rendered="formListStore.onFirstDataRendered"
              :initialState="initialState"
            />
          </div>
        </div>
      </div>
    </ui-content>
  </layout>
</template>

<script>
import Layout from '@/components/layout.vue'
import UiBreadcrumbs from '@/components/ui-breadcrumbs.vue'
import UiListTabs from '@/components/ui-list-tabs.vue'
import UiContent from '@/components/ui-content.vue'
import {AgGridVue} from 'ag-grid-vue3'
import grid from '@/util/grid'
import BREADCRUMBS from '@/util/breadcrumbs'
import {useFormListStore} from '@/stores/FormListStore'
import {storeToRefs} from 'pinia'
import {inject, ref, watch} from 'vue'
import TABS from '@/types/tabs'
import {DateFormatter} from '@/services/dateFormatter'
import FormState from '@/types/formState'
import checkboxRenderer from '@/util/checkboxRenderer'
import WeightEditedIcon from '@/components/weight-edited-icon.vue'
import stateColorRenderer from '@/util/stateColorRenderer'
import {useExternalStore} from '@/stores/ExternalStore'
import DocumentType from '@/types/documentType'
import ContactOrganisationType from '@/types/contactOrganisationType'

export default {
  name:       'ExternalWasteIdentificationList',
  computed:   {
    TABS() {
      return TABS
    },
    grid() {
      return grid
    },
  },
  components: {
    AgGridVue,
    UiContent,
    UiListTabs,
    UiBreadcrumbs,
    Layout,
    // eslint-disable-next-line
    stateColorRenderer,
    // eslint-disable-next-line
    checkboxRenderer,
    // eslint-disable-next-line
    WeightEditedIcon,
  },
  setup:      () => {
    const breadcrumbs = [BREADCRUMBS.EXTERNAL, BREADCRUMBS.EXTERNAL_WASTE_IDENTIFICATION]
    const currentTab  = ref(TABS.ORGANISER.value)
    const $t          = inject('$t')

    const formListStore = useFormListStore()
    formListStore.init('external-waste-identification-list')

    const externalStore = useExternalStore()
    externalStore.init(DocumentType.WASTE_IDENTIFICATION, ContactOrganisationType.ORGANISER)

    const columnDefs = [
      {
        headerName:  $t('waste_identification.list.table.key'),
        field:       'uuid',
        maxWidth:    250,
      },
      {
        headerName:     $t('waste_identification.list.table.date'),
        field:          'transportDate',
        maxWidth:       250,
        filter:         'agDateColumnFilter',
        filterParams:   {
          minValidYear: 2000,
          maxValidYear: 2099,
          // defaultOption: DateFormatter.formatDate(new Date()),
          filterOptions: ['inRange'],
        },
        valueGetter:    (params) => {
          return new Date(params.data.transportDate)
        },
        valueFormatter: (params) => {
          return DateFormatter.formatDate(params.data.transportDate)
        },
      },
      {
        headerName:   $t('waste_identification.list.table.state'),
        field:        'state',
        maxWidth:     400,
        cellRenderer: 'stateColorRenderer',
        filter:       'agSetColumnFilter',
        filterParams: {
          values:         FormState.getAll(true),
          valueFormatter: (val) => {
            return $t('form.state.' + val.value)
          },
        },
      },
    ]

    watch(() => currentTab.value, (newValue) => {
      externalStore.setContactType(newValue)
    })

    return {
      breadcrumbs,
      formListStore,
      ...storeToRefs(formListStore),
      ...storeToRefs(externalStore),
      externalStore,
      currentTab,
      columnDefs,
    }
  },
}

</script>
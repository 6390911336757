const DocumentType = {
    WASTE_IDENTIFICATION: "WASTE_IDENTIFICATION",
    E_CMR: "E_CMR",
    GRONDBANK: "SOIL_BANK",
    GRONDWIJZER: "SOIL_POINTER",
    ANNEX_7: "ANNEX_7",
    DIGID: "DIGID",
}

export default DocumentType

import mod97Checker from "@/services/mod97Checker";
import countryCodes from "@/models/CountryCodes";

const vatNumberValidator = {
  validate: (country, value) => {
    if (['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'EL', 'GR', 'ES', 'FI', 'FR', 'GB', 'HU', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK'].indexOf(country) > -1) {
      const vatPattern = '^((AT)U[0-9]{8}|(BE)(0|1)[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})$'
      const regex                    = new RegExp(vatPattern)
      if (!regex.test(value)) {
        return false
      }
    }
    switch (country) { // TODO add more countries
      case countryCodes.BE: { // https://economie.fgov.be/nl/themas/ondernemingen/kruispuntbank-van/inschrijving-de-kruispuntbank
        if (value.substring(0, 2) !== 'BE') {
          return false
        }
        if (value.length !== 12 || !value[2].match(/[01]/)) {
          return false
        }
        return mod97Checker.check(value.substring(2))
      }
      default: {
        return value.substring(0, 2) !== 'BE'
      }
    }
  },
}

export default vatNumberValidator
<template :key="id">
  <layout>
    <template #header>
      <ui-header :title="$t('grondwijzer.view.title', {id})" back-route="/grondwijzer/list">
        <template #actions>
          <template v-if="!stopping">
            <div class="btn-group">
              <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown"
                      data-bs-toggle="dropdown">
                {{ $t('general.actions') }}
              </button>
              <ul data-cy="contacts-actions" class="dropdown-menu">
                <li
                    v-if="$roles([roles.USER]) && !FormState.isEndState(form.state)"
                    id="shareBtn"
                    :title="!FormState.canShare(form.state) ? $t('waste_identification.view.only_signed_shared') : ''">
                  <button class="dropdown-item" :class="!FormState.canShare(form.state) ? 'disabled' : ''"
                          @click="showShare()">
                    {{ $t('waste_identification.view.share_with_driver') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.OVERVIEWER, roles.USER, roles.DRIVER])"
                    id="changeLogBtn">
                  <button class="dropdown-item" @click="showChangeLog()">
                    {{ $t('waste_identification.view.view_change_log') }}
                  </button>
                </li>
                <li v-if="$roles([roles.OVERVIEWER, roles.USER])"
                    :title="!FormState.canExport(form.state) ? $t('waste_identification.view.draft_no_export') : ''">
                  <button class="dropdown-item" :class="!FormState.canExport(form.state) ? 'disabled' : ''"
                          @click="showExport()">
                    {{ $t('waste_identification.view.export') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.USER])">
                  <button class="dropdown-item" @click="duplicate()">
                    {{ $t('waste_identification.view.duplicate') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.USER]) && !FormState.isEndState(form.state)"
                    id="cancelBtn"
                    :title="!FormState.canCancel(form.state) ? $t('waste_identification.view.only_signed_cancel') : ''">
                  <button class="dropdown-item" :class="!FormState.canCancel(form.state) ? 'disabled' : ''"
                          @click="showCancel()">
                    {{ $t('waste_identification.view.cancel') }}
                  </button>
                </li>
                <li
                    v-if="$flag('empty_container') && $roles([roles.USER]) && !FormState.isEndState(form.state)"
                    id="setStateEmptyBtn"
                    :title="!FormState.canSetStateEmpty(form.state) ? $t('waste_identification.view.only_draft_empty') : ''">
                  <button class="dropdown-item" :class="!FormState.canSetStateEmpty(form.state) ? 'disabled' : ''"
                          @click="showSetStateEmpty()">
                    {{ $t('waste_identification.view.set_state_empty') }}
                  </button>
                </li>
                <li
                    v-if="(canStart || canArrive) && $roles([roles.USER, roles.DRIVER]) && form.state && !FormState.isEndState(form.state)"
                    id="editBtn"
                >
                  <button class="dropdown-item" @click="edit()">
                    {{ $t('waste_identification.view.edit') }}
                  </button>
                </li>
                <li v-if="$flag('ATTACHMENTS')">
                  <button class="dropdown-item" @click="showAttachments()">
                    {{ $t('waste_identification.view.manage_attachments') }}
                  </button>
                </li>
              </ul>
            </div>
            <!-- If we can start/stop then show this as main button, else show edit-->
            <button
                v-if="(!canStart && !canArrive) && $roles([roles.USER]) && form.state && !FormState.isEndState(form.state)"
                type="button" class="btn btn-outline-secondary"
                @click="edit()"><i class="bi-pencil"/>&nbsp;{{ $t('waste_identification.view.edit') }}
            </button>
            <button
                v-if="canStart"
                type="button" class="btn btn-outline-primary"
                @click="start()"
                :disabled="locating"
            >
              {{ $t('waste_identification.view.start_transport') }}
            </button>
            <button
                v-if="canArrive"
                type="button" class="btn btn-outline-primary"
                @click="stop()"
                :disabled="locating"
            >
              {{ $t('waste_identification.view.stop_transport') }}
            </button>
            <button
                v-if="$roles([roles.USER]) && form.state && FormState.canFinish(form.state)"
                type="button" class="btn btn-outline-primary"
                @click="showFinish()"
            >
              <i class="bi-check-circle"/>&nbsp;{{ $t('waste_identification.view.finish') }}
            </button>
          </template>
          <div v-if="stopping">
            <button type="button" class="btn btn-outline-secondary" @click="showEditWeightsModal()" :disabled="saving">
              <i class="bi-pencil"/>&nbsp;{{ $t('share_link.processor.edit_weights') }}
            </button>
          </div>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <spinner v-if="loading"/>
      <template v-if="!loading">
        <template v-if="stopping">
          <template v-if="step === steps.arrive">
            <div id="arrive" class="d-flex justify-content-center">
              <h3 class="d-flex">
                <span class="btn-group pe-3">
                  <button id="signHereBtn" type="button" class="btn btn-outline-secondary"
                          @click="signHere()">{{ $t('shared.sign_here') }}</button>
                  <button id="refuseBtn" type="button" class="btn btn-outline-secondary"
                          @click="showRefuse()">{{ $t('shared.refuse') }}</button>
                </span>
              </h3>
            </div>
          </template>
          <template v-if="step === steps.signing">
            <processor-signature :id="id" :form="form" @processorSet="isProcessorSet=true" @signed="signed()"
                                 :sharelink="false"/>
          </template>
        </template>
        <template v-else>
          <grondwijzer-form-view :form="form" :key="form.lastUpdate"/>
        </template>
      </template>
    </ui-content>
  </layout>
  <grondwijzer-form v-if="!loading" style="display: none" :item="form" :key="id"/>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {computed, ref, watch} from 'vue'
import Spinner from '@/components/spinner'
import {useRouter} from 'vue-router'
import {DateFormatter} from '@/services/dateFormatter'
import Notifier from '@/util/notifier'
import FormState from '@/types/formState'
import OrganisationType from '@/types/organisationType'
import roles from '@/util/roles'
import geolocation from '@/services/geolocation'
import session from '@/util/session'
import ProcessorSignature from '@/partials/waste-identification/Processor-signature'
import DocumentType from '@/types/documentType'
import GrondwijzerForm from '@/partials/grondwijzer/Form'
import GrondwijzerFormView from '@/partials/grondwijzer/FormView'
import {useFormStore} from '@/stores/FormStore'

export default {
  name:       'GrondwijzerView',
  computed:   {
    DocumentType() {
      return DocumentType
    },
    OrganisationType() {
      return OrganisationType
    },
  },
  components: {
    GrondwijzerFormView,
    GrondwijzerForm,
    ProcessorSignature,
    Spinner,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.GRONDWIJZER, BREADCRUMBS.VIEW]
    const notifier    = Notifier()
    const loading     = ref(true)

    const formStore = useFormStore()
    formStore.init(DocumentType.GRONDWIJZER)
    formStore.loadForm(props.id)

    const form     = ref({})
    const loadForm = () => {
      backend.get(`api/documents/${props.id}`).then((r) => {
        if (r.data.type !== DocumentType.GRONDWIJZER) {
          router.push(`/grondwijzer/list`)
        }
        form.value    = r.data
        loading.value = false
      })
    }
    loadForm()

    watch(() => props.id, () => {
      loadForm()
    })

    const router = useRouter()
    const edit   = () => {
      router.push(`/grondwijzer/edit/${props.id}`)
    }

    const exportModal = ref('exportModal')
    const showExport  = () => {
      exportModal.value.modal.open()
    }
    const exportForm  = (language, type) => {
      switch (type) {
        case 'PDF': {
          exportPdf(language)
          return
        }
        case 'JSON': {
          exportJson()
          return
        }
      }
    }

    const exportPdf = (language) => {
      backend.get(`api/documents/${props.id}/pdf?language=${language}`,
          {responseType: 'blob'}).then((result) => {
        if (result.status === 200) {
          const blob = new Blob([result.data], {type: 'application/pdf'})
          const url  = URL.createObjectURL(blob)
          window.open(url)
        } else {
          notifier.error('toast.exporting_failed')
        }
      }).finally(() => {
        exportModal.value.modal.close()
      })
    }

    const exportJson = () => {
      backend.get(`api/documents/${props.id}/json`,
          {responseType: 'blob'}).then((result) => {
        if (result.status === 200) {
          const blob = new Blob([result.data], {type: 'application/json'})
          const url  = URL.createObjectURL(blob)
          window.open(url)
        } else {
          notifier.error('toast.exporting_failed')
        }
      }).finally(() => {
        exportModal.value.modal.close()
      })
    }

    const exportTypes = [
      'PDF',
      'JSON',
    ]
    const exportType  = ref('PDF')

    const date = computed(() => {
      if (!loading.value) {
        return DateFormatter.formatDate(form.value.transportDate)
      }
      return ''
    })

    const updateDate = computed(() => {
      if (!loading.value) {
        return DateFormatter.formatDateTime(form.value.lastUpdate)
      }
      return ''
    })

    const shareModal = ref('shareModal')
    const showShare  = () => {
      shareModal.value.modal.open()
    }
    const share      = async (shareWith) => {
      await backend.post('api/documents/batch', {
        'action': 'SHARE_DRIVER',
        'ids':    [props.id],
        'params': shareWith,
      }).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.sharing_successful')
        } else {
          notifier.error('toast.sharing_failed')
        }
      }).finally(() => {
        shareModal.value.modal.close()
        loadForm()
      })
    }

    const cancelModal = ref('cancelModal')
    const showCancel  = () => {
      cancelModal.value.modal.open()
    }
    const cancel      = async (reason) => {
      await backend.put(`api/documents/${props.id}/cancel`, {
        'reason': reason,
      }).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.cancel_successful')
        } else {
          notifier.error('toast.cancel_failed')
        }
      }).finally(() => {
        cancelModal.value.modal.close()
        loadForm()
      })
    }

    const setStateEmptyModal = ref('setStateEmptyModal')
    const showSetStateEmpty  = () => {
      setStateEmptyModal.value.modal.open()
    }
    const setStateEmpty      = async () => {
      await backend.put(`api/documents/${props.id}/empty`).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.update_successful')
        } else {
          notifier.error('toast.update_failed')
        }
      }).finally(() => {
        setStateEmptyModal.value.modal.close()
        loadForm()
      })
    }

    const finishModal = ref('finishModal')
    const showFinish  = () => {
      finishModal.value.modal.open()
    }
    const finish      = async () => {
      await backend.put(`api/documents/${props.id}/finish`).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.finish_successful')
        } else {
          notifier.error('toast.finish_failed')
        }
      }).finally(() => {
        finishModal.value.modal.close()
        loadForm()
      })
    }

    const changeLogModal = ref('changeLogModal')
    const showChangeLog  = () => {
      changeLogModal.value.modal.open()
    }

    const duplicateModal = ref('duplicateModal')
    const showDuplicate  = () => {
      duplicateModal.value.modal.open()
    }
    const duplicate      = async () => {
      await backend.post(`api/documents/${props.id}/duplicate`).then((result) => {
        if (result.status === 200) {
          backend.get(`api/documents/${props.id}`).then((doc) => {
            form.value = doc.data
            router.push(`/grondwijzer/view/${result.data.id}`)
          })
        } else {
          notifier.error('toast.duplication_failed')
        }
      }).finally(() => {
        duplicateModal.value.modal.close()
      })
    }

    const attachmentsModal = ref('attachmentsModal')
    const showAttachments  = () => {
      attachmentsModal.value.modal.open()
    }
    const saveAttachments  = async () => {
      attachmentsModal.value.modal.close()
      loadForm()
    }

    // ********************
    // start and arrive for DRIVER role

    const canStart = computed(() => {
      return roles.hasOneOfRoles(roles.DRIVER) && FormState.canStart(form.value.state)
    })

    const canArrive = computed(() => {
      return roles.hasOneOfRoles(roles.DRIVER) && FormState.canArrive(form.value.state)
    })

    watch(() => canStart.value, (v) => {
      if (v) {
        geolocation.watchPosition()
      }
    })

    watch(() => canArrive.value, (v) => {
      if (v) {
        geolocation.watchPosition()
      }
    })

    const locating = ref(false)
    geolocation.watchPosition()
    const start = () => {
      locating.value = true
      notifier.notify('toast.getting_location')
      if (!geolocation.getLocation()) {
        setTimeout(() => {
          start()
        }, 3000)
      } else {
        const user         = session.getUser()
        const organisation = session.getOrganisation()
        locating.value     = false
        backend.put(`api/documents/${props.id}/start`, {
          'coordinates':  geolocation.getLocation(),
          'firstName':    user.firstName,
          'lastName':     user.lastName,
          'organisation': organisation.name,
        }).then(() => {
          notifier.success('toast.start_location_saved')
          loadForm()
        }).catch(() => {
          notifier.error('toast.location_failed')
        })
      }
    }

    const steps = {
      arrive:  'arrive',
      signing: 'signing',
    }

    const stopping = ref(false)
    const step     = ref(steps.arrive)
    const stop     = () => {
      stopping.value = true
      step.value     = steps.arrive
    }

    const signHere = () => {
      step.value = steps.signing
    }

    const refuseModal    = ref('refuseModal')
    const showRefuse     = () => {
      refuseModal.value.modal.open()
    }
    const isProcessorSet = ref(false)
    const refuse         = (reason) => {
      refuseModal.value.modal.close()
      stopping.value = false
      notifier.notify('toast.refusing_transport')
      backend.put(`api/documents/${props.id}/refuse`, {reason: reason}).then(() => {
        notifier.success('toast.transport_refused')
        loadForm()
      })
    }

    const signed = () => {
      stopping.value = false
      loadForm()
    }

    // ********************

    const demo = computed(() => {
      return form.value.demo
    })

    return {
      breadcrumbs,
      loading,
      date,
      updateDate,
      edit,
      exportPdf,
      shareModal,
      showShare,
      share,
      cancelModal,
      showCancel,
      cancel,
      finishModal,
      showFinish,
      finish,
      form,
      FormState,
      showChangeLog,
      changeLogModal,
      setStateEmptyModal,
      showSetStateEmpty,
      setStateEmpty,
      duplicateModal,
      showDuplicate,
      duplicate,
      exportModal,
      showExport,
      exportForm,
      exportTypes,
      exportType,
      canStart,
      canArrive,
      start,
      stop,
      stopping,
      locating,
      step,
      steps,
      refuseModal,
      showRefuse,
      refuse,
      signHere,
      signed,
      isProcessorSet,
      attachmentsModal,
      showAttachments,
      saveAttachments,
      demo,
    }
  },
}
</script>

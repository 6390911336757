<template>
  <layout>
    <template #header>
      <ui-header :title="$t('digid.view.title', {id: form.id})" back-route="/external/waste-identification/list">
        <template #actions>
          <div class="btn-group" >
            <button>
              <i class="bi-pencil-square"/>&nbsp;{{ $t('signature.sign_for_delivery') }}
            </button>
          </div>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      {{ form }}
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout.vue'
import UiHeader from '@/components/ui-header.vue'
import UiBreadcrumbs from '@/components/ui-breadcrumbs.vue'
import UiContent from '@/components/ui-content.vue'
import BREADCRUMBS from '@/util/breadcrumbs'
import {useFormStore} from '@/stores/FormStore'
import DocumentType from '@/types/documentType'
import {watch} from 'vue'
import {storeToRefs} from 'pinia'

export default {
  name:       'ExternalWasteIdentificationView',
  components: {
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props: {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const breadcrumbs = [BREADCRUMBS.EXTERNAL, BREADCRUMBS.EXTERNAL_WASTE_IDENTIFICATION, BREADCRUMBS.VIEW]

    const formStore = useFormStore()
    formStore.init(DocumentType.DIGID)

    formStore.loadForm(props.id)

    watch(() => props.id, () => {
      formStore.loadForm(props.id)
    })

    return {
      breadcrumbs,
      formStore,
      ...storeToRefs(formStore),
    }
  }
}
</script>

<template>
  <layout>
    <template #header>
      <ui-header :title="$t('waste_identification.edit.title', {id})" back-route="/external/waste-identification/list">
        <template #actions>

        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout.vue'
import UiHeader from '@/components/ui-header.vue'
import UiBreadcrumbs from '@/components/ui-breadcrumbs.vue'
import UiContent from '@/components/ui-content.vue'
import BREADCRUMBS from '@/util/breadcrumbs'

export default {
  name:       'ExternalWasteIdentificationEdit',
  components: {
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props: {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      () => {
    const breadcrumbs = [BREADCRUMBS.EXTERNAL, BREADCRUMBS.EXTERNAL_WASTE_IDENTIFICATION, BREADCRUMBS.EDIT]

    return {
      breadcrumbs,
    }
  }
}
</script>

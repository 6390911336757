import {defineStore, storeToRefs} from 'pinia'
import {computed, ref} from 'vue'
import {useFormStore} from '@/stores/FormStore'
import session from '@/util/session'
import {useSharedLinkStore} from '@/stores/SharedLinkStore'

export const useChangeLogStore = defineStore('changeLog', () => {
  const changeLogModal  = ref('changeLogModal')
  const formStore       = useFormStore()
  const sharedLinkStore = useSharedLinkStore()
  const form            = ref(null)
  const logs            = computed(() => {
    const temp = form.value?.documentAuditLogs
    if (temp) {
      return temp.reverse()
    }
    return []
  })

  const showChangeLog = () => {
    let temp
    ({form: temp} = session.isAnonymousUser() ? storeToRefs(sharedLinkStore) : storeToRefs(formStore))
    form.value = temp.value
    changeLogModal.value.modal.open()
  }

  return {
    changeLogModal,
    showChangeLog,
    logs,
  }
})
<template>
  <input class="col form-control" type="number" v-model="model" :required="required" @beforeinput="saveInput" @input="validOrReset"
         @blur="includeDigitOrReset" :placeholder="placeholder" :min="min" :step="step" :max="max"/>
</template>

<script>

import {useModelWrapper} from '@/util/modelWrapper'
import {watch} from 'vue'

export default {
  name:  'NumberInput',
  props: {
    modelValue: {
      type:     [String, Number, null],
      required: true,
    },
    required:   {
      type:    Boolean,
      default: false,
    },
    placeholder: {
      type:    String,
      default: '',
    },
    min:          {
      type:    String,
      default: '',
    },
    max:          {
      type:    String,
      default: '',
    },
    maxlength:    {
      type:    Number,
      default: 255,
    },
    step:         {
      type:    String,
      default: '0.00001',
    },
  },
  setup: (props, {emit}) => {
    const model = useModelWrapper(props, emit)

    let currentVal  = null
    const saveInput = (e) => {
      currentVal = e.target.value
    }

    const validOrReset = (e) => {
      if (e.data === 'e') {
        model.value = currentVal
        return
      }
      if (props.max && model.value > props.max) {
        model.value = currentVal
      }
    }

    const digitRegex          = /[0-9]+/
    const includeDigitOrReset = (e) => {
      e.target.classList.remove('invalid-field')
      if (!digitRegex.test(e.target.value)) {
        model.value = ''
      }
    }

    watch(() => props.maxlength, () => {
      fixInput(model.value)
    }, {deep: true})

    const fixInput = () => {
      if (props.maxlength) {
        model.value = model.value.substring(0, props.maxlength)
      }
    }

    return {
      model,
      saveInput,
      validOrReset,
      includeDigitOrReset,
    }
  },
}

</script>
